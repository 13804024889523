<template>
  <div class="content">
    <div class="tasks">
      <div v-if="sendMsgList.length!==0&&!currEntry" class="talkPolicy talkContent">
        <div class="itemText">客户群任务</div>
        <!-- 客户群运营  记得取消之后要刷新列表 @reFreshList='getOspTaskConList'-->
        <div style="margin-bottom:12px">
          <sopList :get-list='sendMsgList' :get-type='1'  @reFreshList='getGroupList'/>
        </div>
        <!-- 内容运营 -->
        <div style="margin-bottom:12px">
          <sopList :get-list='sendMsgList' :get-type='2'  @reFreshList='getGroupList'/>
        </div>
        <!-- 活动运营 -->
        <div style="margin-bottom:12px">
          <sopList :get-list='sendMsgList' :get-type='4'  @reFreshList='getGroupList'/>
        </div>
      </div>
      <!-- 更多客户群 -->
      <div v-if="moreTaskList.length!==0&&!currEntry" class="talkPolicy talkContent">
        <div class="itemText">更多任务客户群-{{moreTaskList.length}}</div>
        <moreTasks :get-more-list='moreTaskList'></moreTasks>
      </div>
      <div v-if="currEntry" class="noContent">请从<b>群聊</b>会话的工具栏进入</div>
      <div v-if="sendMsgList.length===0&&moreTaskList.length===0&&!currEntry&&loading" class="noContent">暂无SOP群任务</div>
    </div>
  </div>

</template>

<script>
import sopList from './component/index.vue'
import moreTasks from './component/moreTasks.vue'
import { getMoreGropeList, getGropeTaskList } from '@/api/modules/sopTaskGrope'
import wxSDK from '@/scripts/wxsdk'
import './constant'

export default {
  components: {
    sopList,
    moreTasks
  },
  data() {
    return {
      sendMsgList: [],
      moreTaskList: [],
      currchatId: '',
      currEntry: false,
      loading: false
    }
  },
  async created() {
    await wxSDK.config([], ['getContext', 'getCurExternalChat'])
    this.getChatId()
    this.isEntry()
  },
  async mounted() {
    this.load()
  },
  methods: {
    load() {
      setTimeout(async() => {
        this.loading = !this.loading
      }, 500)
    },
    // 判断入口
    async isEntry() {
      const entry = await wxSDK.getContext()
      if (entry !== 'group_chat_tools') {
        // alert('请从群聊进入')
        this.currEntry = true
      }
    },
    // 获取当前群id
    async getChatId() {
      this.currchatId = await wxSDK.getCurExternalChat()
      console.log('currchatId===', this.currchatId)
      this.getSopTaskConList()
      this.getGroupList()
    },
    // 获取内务内容列表
    async getSopTaskConList() {
      const res = await getGropeTaskList({ qwChatId: this.currchatId })
      if (res.code === 0) {
        this.sendMsgList = res.data
      }
    },
    // 获取更多群信息列表
    async getGroupList() {
      const res = await getMoreGropeList({ qwChatId: this.currchatId })
      if (res.code === 0) {
        this.moreTaskList = res.data
      }
    }
  }
}
</script>

<style lang='less' scoped>
.content{
  overflow-y: scroll;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
  .tasks{
    width: 100%;
    height: 100%;
    position: relative;
    .talkPolicy{
          background: #ffffff;
          border-radius: 4px;
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.03);
          padding: 12px;
          margin-bottom: 12px;
          .itemText{
            font-size: 16px;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            border-bottom: 1px solid #f7f6f6;
            padding-bottom: 12px;
            margin-bottom: 12px;
          }
          .ulCon{
            height: 100%;
            line-height: 1.4;
            // overflow: hidden;
            li{
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              color: #666666;
              line-height: 1.4;
              padding: 5px 0;
              display: block;
            }
            li:nth-child(3){
              display: none;
            }
          }
          .btnSty{
            display: flex;
            justify-content: flex-end;
              .extBtn,.cloBtn{
                background-color: #ffffff;
                opacity: 1;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                color: #287de1;
                border: none;
                padding: 5px 15px;
            }
          }
          .cueItems{
          padding: 6px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .titcon{
            display: flex !important;
            flex-direction: row;
            align-items: center;
            // max-width: 50%;
            width: 100%;
            margin-left: -4px;
            .titcon-item{
                // width: 100%;
                max-width: calc(100% - 50px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 5px;
              }
              .titcon-item,span{
                font-size: 14px;
                color: #666666;
              }
              .ot{
                color: red;
              }
          }

          .btnGrop{
            display: flex;
            flex-direction: row;
            text-align: center;
            align-items: center;
            // width: 100px;
            .userName{
              width: 50px;
            }
            .doneBtn{
              // width: 44px;
              padding: 0;
              line-height: 20px;
              .qwIcon{
                display: block;
                margin: auto;
              }
              &:last-child{
                margin-left: 12px;
              }
            }

          }

        }
      }
      .noContent{
        font-size: 23px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
</style>
