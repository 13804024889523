const gateway = '/dapi/api-gateway/h5/scrm-qwagg-service'
import { post } from '@/api/index'
// 获取群群群任务列表
export const getGropeTaskList = (params) => {
  return post(gateway + `/api/v1/task/content/get_group_task_list`, params)
}
// SOP群群群任务操作，完成、取消、发送
export const getGropeTaskOperate = (params) => {
  return post(gateway + `/api/v1/sop/group/task/sop_group_task_execute?id=${params.id}&tempt=${params.tempt}`)
}
// SOP群更多更多任务列表
export const getMoreGropeList = (params) => {
  return post(gateway + `/api/v1/task/content/get_more_group_param`, params)
}
