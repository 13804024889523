<template>
  <div>
    <div v-for="(item,index) in getMoreList" :key="index">
      <div class="cueItems">
        <taskList :get-item='item'></taskList>
        <div class="btnGrop">
          <div class="userName">
            <div class="onellipsis">{{item.chatName}}</div></div>
          <div class="doneBtn" @click="toGroupChat(item.qwChatId)">
            <i class="iconfont iconqiyeweixin qwIcon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskList from './taskList.vue'
import wxSDK from '@/scripts/wxsdk'
export default {
  components: {
    taskList
  },
  props: {
    getMoreList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  async created() {
    await wxSDK.config([], ['openExistedChatWithMsg'])
  },
  methods: {
    // 打开群聊
    toGroupChat(qwChatId) {
      console.log(qwChatId, '获取到的群id')
      wxSDK.openExistedChatWithMsg(qwChatId)
      // wx.invoke('openExistedChatWithMsg', {
      //   // chatId: 'wr8HDpDgAA3i3IIEHCbxtFr8oPphJ22A',
      //   chatId: qwChatId
      // }, function(res) {
      //   console.log(res, 'open群聊')
      //   if (res.err_msg === 'openExistedChatWithMsg:ok') {
      //     console.log()
      //   }
      // })
    }
  }
}
</script>

<style lang='less' scoped>
  .cueItems{
      padding: 6px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .btnGrop{
        display: flex;
        flex-direction: row;
        // text-align: center;
        align-items: center;
        // width: 100px;
        .userName{
          font-size: 14px;
          color: #666666;
          width: 60px;
        }
        .doneBtn{
          // width: 44px;
          padding: 0;
          // line-height: 20px;
          .qwIcon{
            display: block;
            margin: auto;
            color: #1296db;
          }
          &:last-child{
            // margin-left: 12px;
          }
        }

      }

    }
</style>
