<template>
  <div class="titcon onellipsis">
    <div v-if="getItem.contentType==='text'" class="titcon-item">
      <div class="iconbox"><i class="iconfont iconwenan"></i></div>
      <div class="onellipsis">{{getItem.content}}</div>
    </div>
    <div v-else-if="getItem.contentType==='image'" class="titcon-item">
      <div class="iconbox"><i class="iconfont icontupian"></i></div>
      <div class="onellipsis">{{getItem.fileName}}</div>
    </div>
    <div v-else-if="getItem.contentType==='video'" class="titcon-item">
      <div class="iconbox"><i class="iconfont iconshipin"></i></div>
      <div class="onellipsis">{{getItem.fileName}}</div>
    </div>
    <div v-else-if="getItem.contentType==='file'" class="titcon-item">
      <div class="iconbox"><i class="iconfont iconwenjian"></i></div>
      <div class="onellipsis">{{getItem.fileName}}</div>
    </div>
    <div v-else-if="getItem.contentType==='news'" class="titcon-item">
      <div class="iconbox"><i class="iconfont icontuwen"></i></div>
      <div class="onellipsis">{{getItem.title}}</div
      ></div>
    <div v-else-if="getItem.contentType==='miniprogram'" class="titcon-item">
      <div class="iconbox"><i class="iconfont iconxiaochengxu"></i></div>
      <div class="onellipsis">{{getItem.title}}</div>
    </div>
    <div v-if="getItem.sendStatus" class="ot">(逾期)</div>
    <div v-if="getItem.status" class="ot">(已完成)</div>
  </div>
</template>

<script>

export default {
  props: {
    getItem: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang='less' scoped>
.onellipsis{
  display: block !important;
  overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
  word-break: break-all;
}
.titcon{
  display: flex !important;
  flex-direction: row;
  align-items: center;
  // max-width: 50%;
  width: 100%;
  // margin-left: -4px;
  .titcon-item{
      // width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: calc(100% - 55px);
      margin-right: 5px;
      font-size: 14px;
      color: #666666;
      .iconbox{
        .iconfont{
        display: block;
        color: #1296db;
        margin-right: 4px;
        }
      }
    }
    .icon_t{
      width: 16px;
      margin-right: 4px;
      display: block;
    }
    .ot{
      color: red;
      font-size: 14px;
      min-width: 50px;
      // font-size: 20px;
    }
}
</style>
