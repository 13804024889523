<template>
  <div class="custCon">
    <div v-if="itemCount>0" class="cusTit"  v-html="title"></div>
    <div v-for="(item,index) in getList" :key="index">
      <div v-if="getType===item.sopType"  class="cueItems">
        <taskList :get-item='item'></taskList>
        <div class="btnGrop">
          <button @click="sendMessageBtn(index)">发送</button>
          <button v-if="item.sendStatus && !isSend[item.id]" class="cancelBtn" @click="cancelSend(index)">取消</button>
          <button v-else  class="doneBtn" @click="finishSend(index)">完成</button>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <van-dialog v-model="showDia" message-align show-cancel-button @confirm='confirmEvent'>
      <div class="diaBox">
        <div v-if="sendList.contentType==='text'" class="dialog">
          <span>{{sendList.content}}</span>
        </div>
        <div v-else-if="sendList.contentType==='image'" class="dialog">
          <img class="diaImg" :src="sendList.imgUrl" alt="">
        </div>
        <div v-else-if="sendList.contentType==='video'" class="dialog">
          <video class="viedoSty" :src="sendList.imgUrl" controls="controls"></video>
        </div>
        <div v-else-if="sendList.contentType==='file'" class="dialog">
          <span>{{sendList.fileName}}</span>
        </div>
        <div v-else-if="sendList.contentType==='news'" class="dialog diaNew">
          <div class="ellipsis2 newD1">{{sendList.title}}</div>
          <div class="diaN_con">
            <div class="ellipsis2 newD">{{sendList.desc}}</div>
            <img class="diaImg1" :src="sendList.imgUrl" alt="">
          </div>
        </div>
        <div v-else-if="sendList.contentType==='miniprogram'" class="dialog">
          <div class="diaApp">
            <div class="tmpTop">
              <img src="@/assets/img/add.png" alt="" class="imgTmp">
              <div class="textTmp">问卷调查助手君</div>
            </div>
            <h4>{{sendList.title}}</h4>
            <span class="itenNde">{{sendList.desc}}</span>
            <img class="diaImg" :src="sendList.imgUrl" alt="">
            <div class="appBott">
              <i class="iconfont icona-xiaochengxu11"></i>
              <span class="inter">{{sendList.page}}小程序</span>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { isMobileClient } from '@/utils/utils'
import { getGropeTaskOperate } from '@/api/modules/sopTaskGrope'
import { Toast, Dialog } from 'vant'
import taskList from './taskList.vue'
import wxSDK from '@/scripts/wxsdk'
export default {
  components: {
    taskList
  },
  props: {
    getList: {
      type: Array,
      default: () => {}
    },
    getType: {
      type: Number,
      default: 0
    },
    config: {
      type: Object,
      default: () => ({
        1: {
          title: '客户群运营'
        },
        2: {
          title: '内容运营'
        },
        4: {
          title: '活动运营'
        }
      })
    }
  },
  data() {
    return {
      sendList: {},
      showDia: false,
      currType: false,
      id: '',
      medId: '',
      iURL: '',
      isSend: {}
    }
  },
  computed: {
    title: function() {
      return this.config[this.getType].title
    },
    itemCount: function() {
      let num = 0
      this.getList.forEach(element => {
        if (element.sopType === this.getType) {
          num++
        }
      })
      return num
    }
  },
  watch: {
    // 监听value变化（slect控件不是必要，暂时不明）
    // getList(newValue, oldValue) {
    //   this.getList = newValue
    //   console.log(newValue)
    // },
    // deep:true
  },
  async created() {
    await wxSDK.config([], ['sendChatMessage'])
  },
  mounted() {
    // await this.getIsTimeOut()
  },
  methods: {
    // 分享消息到当前会话
    async sendMessageBtn(index) {
      this.sendList = this.getList[index]
      this.id = this.getList[index].id
      const data = {
        id: this.id,
        tempt: 1
      }
      // 获取mediaid
      this.medId = ''
      const res = await getGropeTaskOperate(data)
      console.log(res, 770)
      this.medId = res?.data?.mediaId || ''
      this.iURL = res?.data?.url || ''
      console.log(this.medId, 665)
      // 判断是否在移动端
      if (isMobileClient()) {
        console.log('在移动端')
        this.sendFun()
      }
      else {
        console.log('在PC端')
        this.showDia = !this.showDia
      }
    },
    // 确认发送
    async confirmEvent() {
      // this.isoverTime = false
      this.sendFun()
    },
    // 发送的内容方法
    async sendFun() {
      if (this.sendList.sendStatus) {
        this.isSend[this.id] = true
      }
      let obj = {}
      // this.sendList.contentType = 'file'
      switch (this.sendList.contentType) {
        case 'text':
          obj = {
            content: this.sendList.content // 文本内容
          }
          break
        case 'image':
          obj = {
            mediaid: this.medId // 图片的素材id
          }
          break
        case 'video':
          obj = {
            mediaid: this.medId // 视频的素材id
          }
          break
        case 'file':
          obj = {
            mediaid: this.medId // 文件的素材id
          }
          break
        case 'news':
          obj = {
            link: this.sendList.linkUrl, // H5消息页面url 必填
            title: this.sendList.title, // H5消息标题
            desc: this.sendList.desc, // H5消息摘要
            imgUrl: this.sendList.imgUrl // H5消息封面图片URL
          }
          break
        case 'miniprogram':
          obj = {
            appid: this.sendList.miniAppId, // 小程序的appid
            title: this.sendList.title, // 小程序消息的title
            imgUrl: this.sendList.imgUrl, // 小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
            page: this.sendList.miniAapPage // 小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
          }
          break

        default:
          break
      }
      const send = {
        msgtype: this.sendList.contentType, // 消息类型，必填
        enterChat: true, // 为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        ...obj
      }
      console.log('obj===', { ...obj })
      await wxSDK.sendChatMessage(send.msgtype, { ...obj }, send.enterChat)
    },
    // 取消发送
    async cancelSend(index) {
      this.id = this.getList[index].id
      // console.log(this.id,111)
      const data = {
        id: this.id,
        tempt: 3
      }
      Dialog.confirm({
        // title: '标题',
        message: '确认取消？'
      })
        .then(async() => {
          // on confirm
          const res = await getGropeTaskOperate(data)
          if (res.code === 0) {
            this.getList.splice(index, 1)
            this.$emit('reFreshList')
          }
          // console.log(res)
        })
        .catch(() => {
          // on cancel
        })
    },
    // 发送完成
    async finishSend(index) {
      this.id = this.getList[index].id
      const data = {
        id: this.id,
        tempt: 2
      }
      const res = await getGropeTaskOperate(data)
      if (res.code === 0) {
        Toast('已完成')
        this.getList.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang='less'>
.cusTit{
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  padding-bottom: 4px;
}
.custCon{
    .cueItems{
      padding: 6px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .btnGrop{
        display: flex;
        flex-direction: row;
        color: #287de1;
        text-align: center;
        button{
          width: 44px;
          padding: 0;
          font-size: 12px;
          // font-family: PingFang SC, PingFang SC-Regular;
          line-height: 20px;
          border: 1px solid #287de1;
          background-color: #ffffff;
          // margin-left: 5px;
          &:last-child{
            margin-left: 12px;
          }
        }
      }
    }
    .diaBox{
      height: auto;
      max-height: 300px;
      overflow-y: scroll;
        .dialog{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
          max-width: 70%;
          max-height:60%;
          margin: 0 auto;
          overflow-wrap: break-word;
          word-break: break-all;
          .diaImg{
              width: calc(100% - 10px);
              max-height: 100%;
              // max-width: 320px;
              // height: 120px;
          }
          .viedoSty{
            width: calc(100% - 10px);
            max-height: 100%;
              // max-width: 320px;
              // max-height: 320px;
          }
        }
      }
          .diaNew{
            max-width: 70%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: flex-start;
            .newD1{
              color: #000;
            }
            .diaN_con{
              // width: 300px;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between !important;
              margin-top: 5px;
              .diaImg1{
                margin-left: 5px;
              // width: 50px;
              max-height: 35px;
            }
            .newD{
              font-size: 13px;
              color: #666;
              }
            }
          }
          .diaApp{
            // width: 250px;
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // border-bottom: 1px solid #999;
            border-radius: 3px;
            .tmpTop{
              display: flex;
              align-items: center;
                .imgTmp{
                width: 18px;
                margin-right: 4px;
              }
              .textTmp{
                color: #999;
                font-size: 12px;
              }
            }

            h4{
              margin: 8px 0;
              font-weight: normal;
            }
            .itenNde{
              color: #666666;
            }
            .diaImg{
              max-width: 300px;
              margin-bottom: 8px;
            }
            .appBott{
              border-top: 1px solid #f7f6f6;
              width: 100%;
              padding: 4px;
              display: flex;
              align-items: center;
              .icona-xiaochengxu11{
               color: #287de1;
              }
              .inter{
                font-size: 12px;
                color: #999;
              }
            }
          }
        }
</style>
