/* eslint-disable no-unused-vars */
import { getJssdkConfig } from '@/api/modules/userAuth'
import dynamicLoadScript from '@/scripts/dynamicLoadScript'
import { isWxwork, isDevtools, sleep } from './utils'
import store from '@/store/index'

const jssdkScriptUrl = '//res.wx.qq.com/open/js/jweixin-1.2.0.js'

class WxSDK {
  hasInitConfig = false

  setUpJsApiList = [
    'hideOptionMenu',
    'hideMenuItems',
    'sendChatMessage',
    'getContext',
    'getCurExternalChat',
    'openExistedChatWithMsg'
  ]
  // eslint-disable-next-line no-useless-constructor
  constructor() {
  }

  init() {
    dynamicLoadScript(jssdkScriptUrl)
  }

  // 企业微信初始化jssdk需要配置的
  initConfigOnAppStart() {
    window.wx.hideOptionMenu()
    window.wx.hideMenuItems({
      menuList: [
        'menuItem:share:appMessage',
        'menuItem:share:timeline',
        'menuItem:share:wechat',
        'menuItem:favorite',
        'menuItem:copyUrl',
        'menuItem:openWithSafari',
        'menuItem:share:email',
        'menuItem:share:appMessage',
        'menuItem:share:qq',
        'menuItem:share:weiboApp',
        'menuItem:share:facebook',
        'menuItem:share:QZone',
        'menuItem:editTag',
        'menuItem:delete',
        'menuItem:originPage',
        'menuItem:readMode',
        'menuItem:openWithQQBrowser'
      ]
    })
  }

  /**
   * 通过config注入企业微信的身份与权限
   * @param {string[]} jsApiList 需要配置使用的JS接口列表
   */
  async wxConfig(signConf, jsApiList = []) {
    await checkInvalidAccess()
    await isJssdkReady()
    return new Promise((resolve, reject) => {
      const { corpId, nonceStr, signature, timestamp } = signConf
      if (!this.hasInitConfig) {
        // 没有初始化配置时需要加上接口权限
        jsApiList = jsApiList.concat(this.setUpJsApiList)
      }
      const _config = {
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: corpId, // 必填，企业微信的corpID
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      }
      window.wx.config(_config)

      window.wx.ready(() => {
        resolve()
        if (!this.hasInitConfig) {
          // this.initConfigOnAppStart()
          this.hasInitConfig = true
        }
      })
      window.wx.error(res => reject(new Error(res.errMsg)))
    })
  }

  /**
   * 通过agentConfig注入企业微信应用的身份与权限
   * - 部分接口需要调用agentConfig才能使用
   * @param {string[]} jsApiList 需要使用的JS接口列表
   */
  async wxAgentConfig(signConf, jsApiList = []) {
    await checkInvalidAccess()
    await isJssdkReady()
    return new Promise((resolve, reject) => {
      const { agentId, nonceStr, signature, timestamp } = signConf
      const _config = {
        corpid: global.APP_CONFIG.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      }
      window.wx.agentConfig({
        ..._config,
        success: function() {
          resolve()
        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            reject(new Error('版本过低请升级'))
          }
          reject(new Error(res.errMsg))
        }
      })
    })
  }
  /**
   * 获取进入H5页面的入口环境
   * - 必须先成功调用agentConfig，否则调用时会报“no permission”错误
   * - config注入的是企业的身份与权限，而agentConfig注入的是应用的身份与权限。尤其是当调用者为第三方服务商时，通过config无法准确区分出调用者是哪个第三方应用，而在部分场景下，又必须严谨区分出第三方应用的身份，此时即需要通过agentConfig来注入应用的身份信息。
   */
  async config(wxJsApiList = [], agentJsApiList = []) {
    await getJssdkConfig({
      url: getUrl()
    }).then(async config => {
      config = config.data || {}
      const { corpSign, agentSign } = config
      if (!isDevtools()) {
        await this.wxConfig(corpSign, wxJsApiList)
        await this.wxAgentConfig(agentSign, agentJsApiList)
      }
    })
  }

  /**
   * 获取进入H5页面的入口环境
   * - 必须先成功调用agentConfig，否则调用时会报“no permission”错误
   */
  getContext() {
    return new Promise((resolve, reject) => {
      window.wx.invoke('getContext', {}, function(res) {
        if (res.err_msg === 'getContext:ok') {
          const entry = res.entry // 返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools
          resolve(entry)
        }
        else {
          // 错误处理
          reject(new Error(res.errMsg))
        }
      })
    })
  }

  /**
   *获取当前聊天窗口的ChatiId
   */
  getCurExternalChat() {
    return new Promise((resolve, reject) => {
      window.wx.invoke('getCurExternalChat', {}, function(res) {
        console.log('contextRes: ', res)
        if (res.err_msg === 'getCurExternalChat:ok') {
          const chatId = res.chatId
          resolve(chatId)
        }
        else {
          console.log('getGroupId failed')
          reject()
        }
      })
    })
  }

  /**
   * 打开已有群聊并发送消息
   */
  openExistedChatWithMsg(chatId) {
    return new Promise((resolve, reject) => {
      window.wx.invoke('openExistedChatWithMsg', {
        chatId: chatId
      }, function(res) {
        console.log(res, 'openExistedChatWithMsg')
        if (res.err_msg === 'openExistedChatWithMsg:ok') {
          console.log('openExistedChatWithMsg failed')
        }
      })
    })
  }

  /**
   * 获取当前外部联系人userid
   * - 从外部联系人的profile、聊天工具栏进入页面时才可成功调用该接口
   */
  getCurExternalContact() {
    return new Promise((resolve, reject) => {
      window.wx.invoke('getCurExternalContact', {}, function(res) {
        if (res.err_msg === 'getCurExternalContact:ok') {
          console.log('外部联系人：', res)
          resolve(res.userId) // 返回当前外部联系人userId
        }
        else {
          // 错误处理
          reject(new Error(res.errMsg))
        }
      })
    })
  }

  /**
   * 打开外部联系人聊天框
   * @param {string} externalUserId 外部联系人userId
   * @param {string} custMobie 联系人手机号 (业务中极端场景下，当前登陆人并没有添加该客户微信，此时需要提示复制此手机号添加为联系人)
   */
  openExternalUserChat(externalUserId, custMobie) {
    return new Promise((resolve, reject) => {
      window.wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: externalUserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 必填，会话名称。单聊时该参数传入空字符串""即可。
        success: resolve,
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            reject(new Error('企微版本过低请升级'))
            // } else if (res.errMsg === 'openEnterpriseChat:invalid ids') {
            //   Vue.prototype.$dialog.confirm({
            //     title: '提示',
            //     message: `
            //       <div>未添加客户微信，无法使用企微联系。</div>
            //       <div>请先添加客户为联系人</div>
            //       <div style="text-align:center; color:#101010; margin-top:0.2rem">客户手机号：${custMobie}</div>
            //     `,
            //     confirmButtonText: '复制手机号',
            //     className: 'ams-cutomer-no-wechat-tip'
            //   }).then(() => {
            //     copyText(custMobie, function () {
            //       Vue.prototype.$notify({
            //         type: 'success',
            //         message: '复制成功'
            //       })
            //     })
            //   })
            //     .catch(() => {
            //       // on cancel
            //     })
          }
          else {
            reject(new Error(res.errMsg))
          }
        }
      })
    })
  }

  /**
   * 聊天工具栏分享消息到会话
   * @param {*} msgtype 消息类型，必填
   * @param {*} contentJson 消息对象
   * text: {
        content:"你好", //文本内容
    },
    image:
    {
        mediaid: "", //图片的素材id
    },
    video:
    {
        mediaid: "", //视频的素材id
    },
    file:
    {
       mediaid: "", //文件的素材id
    },
    news:
    {
        link: "", //H5消息页面url 必填
        title: "", //H5消息标题
        desc: "", //H5消息摘要
        imgUrl: "", //H5消息封面图片URL
    }
   * @memberof WxSDK
   */
  sendChatMessage(msgtype, contentJson, enterChat = false) {
    return new Promise((resolve, reject) => {
      let option = {}
      if (enterChat) {
        option = {
          msgtype: msgtype,
          [msgtype]: contentJson,
          enterChat: true
        }
      }
      else {
        option = {
          msgtype: msgtype,
          [msgtype]: contentJson
        }
      }
      window.wx.invoke('sendChatMessage', option, function(res) {
        console.log(res)
        if (res.err_msg === 'sendChatMessage:ok') {
          // 发送成功
          resolve(true)
        }
        else {
          // reject(new Error('分享失败'))
          reject(res)
        }
      })
    })
  }
  /**
   * 分享接口--自定义转发到会话
   * * @param {*} contentJson 消息对象
   */
  shareAppMessage(contentJson) {
    return new Promise((resolve, reject) => {
      window.wx.invoke(
        'shareAppMessage',
        {
          ...contentJson
        },
        // {
        //   title: '', // 分享标题
        //   desc: '', // 分享描述
        //   link: '', // 分享链接
        //   imgUrl: '' // 分享封面
        // },
        function(res) {
          console.log('转发到会话', res)
          if (res.err_msg === 'shareAppMessage:ok') {
            resolve(res)
          }
          else {
            // reject(new Error('分享失败'))
            reject(res)
          }
        }
      )
    })
  }

  /**
   * 客户联系--群发消息给客户
   * * @param {*} contentJson 消息对象
   */
  shareToExternalContact(contentJson) {
    return new Promise((resolve, reject) => {
      wx.invoke(
        'shareToExternalContact',
        {
          text: {
            content: '群发消息' // 文本内容
          },
          attachments: [
            {
              msgtype: 'image', // 消息类型，必填
              image: {
                mediaid: '', // 图片的素材id
                imgUrl:
                  'https://astro-zt-test-1304913914.cos.ap-shanghai.myqcloud.com//e0590907-608f-406d-8799-965089813da7_material.jpeg' // 图片的imgUrl,跟图片mediaid填其中一个即可
              }
            },
            {
              msgtype: 'image', // 消息类型，必填
              image: {
                mediaid: '', // 图片的素材id
                imgUrl:
                  'https://astro-zt-test-1304913914.cos.ap-shanghai.myqcloud.com//e0590907-608f-406d-8799-965089813da7_material.jpeg' // 图片的imgUrl,跟图片mediaid填其中一个即可
              }
            },
            {
              msgtype: 'link', // 消息类型，必填
              link: {
                title: '', // H5消息标题
                imgUrl: '', // H5消息封面图片URL
                desc: '', // H5消息摘要
                url: '' // H5消息页面url 必填
              }
            },
            {
              msgtype: 'miniprogram', // 消息类型，必填
              miniprogram: {
                appid: '', // 小程序的appid
                title: '', // 小程序消息的title
                imgUrl: '', // 小程序消息的封面图。必须带http或者https协议头
                page: '' // 小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
              }
            },
            {
              msgtype: 'video', // 消息类型，必填
              video: {
                mediaid: '' // 视频的素材id
              }
            },
            {
              msgtype: 'file', // 消息类型，必填，从3.1.12版本开始支持
              file: {
                mediaid: '' // 文件的素材id，必填
              }
            }
          ]
        },
        function(res) {
          if (res.err_msg === 'shareToExternalContact:ok') {
            resolve(res)
          }
          else {
            reject(res)
          }
        }
      )
    })
  }
}

const isJssdkReady = async() => {
  if (window.wx) return true
  if (!document.getElementById(jssdkScriptUrl)) {
    dynamicLoadScript(jssdkScriptUrl)
  }
  await sleep(50)
  return await isJssdkReady()
}

const checkInvalidAccess = async() => {
  if (!isWxwork()) {
    return Promise.reject(new Error('jssdk功能请在企业微信客户端中测试'))
  }
}

const getUrl = () => {
  // 兼容修复mac3.1.18版本签名后配置agentConfig报错
  const isMacintosh =
    window.navigator.userAgent.indexOf('Macintosh') !== -1 &&
    window.navigator.userAgent.indexOf('wxwork/3.1.18') !== -1
  const url =
    isMacintosh && window.location.href.indexOf('?') === -1
      ? window.location.href.split('#')[0] + '?'
      : window.location.href.split('#')[0]
  return url
}

export default new WxSDK()
