/**
 * dynamicLoadScript
 * @param {*} src script url
 */
export default function dynamicLoadScript(src) {
  return new Promise((resolve, reject) => {
    const exitingScript = document.getElementById(src)
    if (exitingScript) {
      return resolve()
    }
    const script = document.createElement('script')
    script.src = src // src url for the third-party library being loaded.
    script.id = src
    script.async = false
    const onend = 'onload' in script ? stdOnEnd : ieOnEnd
    onend(script)
    document.body.appendChild(script)

    function stdOnEnd(script) {
      script.onload = function() {
        this.onerror = this.onload = null
        resolve()
      }
      script.onerror = function() {
        // this.onload = null here is necessary
        // because even IE9 works not like others
        this.onerror = this.onload = null
        deleteScriptIfLoadFailed()
        reject(new Error('Failed to load ' + this.src))
      }
    }

    function ieOnEnd(script, cb) {
      script.onreadystatechange = function() {
        if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
        this.onreadystatechange = null
        resolve() // there is no way to catch loading errors in IE8
      }
    }

    function deleteScriptIfLoadFailed() {
      setTimeout(() => {
        script.parentElement.removeChild(script)
      }, 0)
    }
  })
}
